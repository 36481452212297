declare const process: { env: { [key: string]: string | undefined } };

/**
 * Basic properties of the application as they are presented on the user
 * interface.
 */
export const appDescriptor = {
    name: "Partner Plast",
};

/**
 * Whether the app is being built in production mode. This is set by
 * Webpack at bundling time. In the absence of a Webpack bundler, this
 * will evaluate to `false`.
 */
export const isInProductionMode = process.env.NODE_ENV === "production";

/**
 * The default locale for Numbro and other locale-aware libraries that we
 * might use.
 */
export const defaultLocale = "en-US";

/**
 * Whether API calls should be made only if we are currently authenticated
 * to the API.
 */
export const loginRequired = true;

/**
 * Waiting time between upload status checks.
 */
export const waitingTimeInMilisecondsBeforeUploadStatusCheck = 1000;

/**
 * Max number of iterations in file upload status check.
 */
export const defaultMaxNumberOfIterationsForFileUploadStatusCheck = 5;

/**
 * Event for product requirement refreshing.
 */
export const productRequirementRefreshEvent = "productRequirementRefresh";

/**
 * Event for schedule chart refreshing.
 */
export const scheduleChartRefreshEvent = "scheduleChartRefresh";

/**
 * Wheter the perspective settings menu button is enabled.
 */
export const isPerspectiveSettingsMenuButtonEnabled = true;

/**
 * Constant for workbench in local storage.
 */
export const localStorageConstantForWorkbench = "workbench";

/**
 * Constant for drawers state in local storage.
 */
export const localStorageConstantForDrawers = "drawers";

/**
 * Constant for sidebar state in local storage.
 */
export const localStorageConstantForSidebar = "sidebar";

/**
 * Constant for perspectives state in local storage.
 */
export const localStorageConstantForPerspectives = "perspectives";

/**
 * Constant for selected perspective id in local storage.
 */
export const localStorageConstantForSelectedPerspectiveId = "selectedPerspectiveId";

/**
 * Constant for version of the stored data in local storage.
 */
export const localStorageConstantForLocalStorageVersion = "version";

/**
 * Version number for the stored data in the local storage.
 */
export const localStorageVersion = "2019-03-27";

/**
 * Constant for user data scope change event.
 * Emited by eventBus when user changes its data scope.
 */
export const userDataScopeChangeEvent = "userDataScopeChange";

/**
 * Constant for forecast calculation panel event
 * When forecast panel finishes the calculation this event
 * is emitted to refresh forecast chart (if displayed)
 */
export const forecastCalculationChangeEvent = "forecastCalculationChange";

/**
 * Constant for safety stock calculation panel event
 * When safety stock panel finishes the calculation this event
 * is emitted to refresh forecast chart (if displayed)
 */
export const safetyStockCalculationChangeEvent = "safetyStockCalculationChange";

export const refreshProductionLineStatusTableEvent = "refreshProductionLineStatusTableEvent";

export const refreshShiftPlanEvent = "refreshShiftPlanEvent";

export const refreshMRPScenariosEvent = "refreshScenariosEvent";

export const batchPlanEditorInitValuesChangeEvent = "batchPlanEditorInitValuesChangeEvent";

export const refreshProductionScheduleChartEvent = "refreshProductionScheduleChartEvent";

export const refreshProductionPlanTableEvent = "refreshProductionPlanTableEvent";

export const updateProductionPlanCreatorStorageEvent = "updateProductionPlanCreatorStorageEvent";

export const markTargetAdapterOnDragEvent = "markTargetAdatersOnDragEvent";

export const unmarkTargetAdapterOnDragEvent = "unmarkTargetAdatersOnDragEvent";

export const refreshProcessingTimesTagEvent = "refreshProcessingTimesTagEvent";

/**
 * Method key for constant forecast algorithm
 */
export const PREDEFINED_CONSTANT_FORECAST_METHOD = 10001;

/**
 * Method key for constant safety stock algorithm
 */
export const PREDEFINED_CONSTANT_SAFETY_STOCK_METHOD = -1;

/**
 * Right now we plan the production for products in moulding category.
 * Therefore the constant has value = 2
 */
export const DEFAULT_NODE_FOR_PRODUCTION_PLAN = 2;

export const PRODUCT_TYPE_SHELL_KEY = "shell";

export enum ProductionQueueRowHeights {
    ProductionQueueRowSmall = 135,
    ProductionQueueRowNormal = 200,
    ProductionQueueRowLarge = 265,
    ProductionQueueRowExtraLarge = 330,
}

export const setAsDefaultTemplateEvent = "setAsDefaultTemplateEvent";

export const refreshProductionOrderSuggestFormElementEvent = "refreshProductionOrderSuggestFormElementEvent";

export const SALES_ORDER_TYPE_KEY = "SO";
