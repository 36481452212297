/**
 * @file Splash screen for the application that is shown while the JavaScript
 * code chunks of the main application are being downloaded.
 */

import * as React from "react";
import CoverPage from "react-cover-page";

import { appDescriptor } from "./constants";

export const SplashScreen = ({ promise }: { promise: Promise<unknown> }): JSX.Element => (
    <CoverPage
        promise={promise}
        title={appDescriptor.name}
        loadingMessage="Please wait, loading application..."
        style={{ zIndex: 10 }}
    />
);
