// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */
.bp4-numeric-input.bp4-small .bp4-input-group .bp4-input {
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
}
.bp4-numeric-input.bp4-small .bp4-input-group .bp4-input:not(:first-child) {
  padding-left: 24px;
}
.bp4-numeric-input.bp4-small .bp4-input-group > .bp4-icon {
  margin: 4px;
}
.bp4-numeric-input.bp4-small .bp4-button {
  min-width: 24px;
}
.bp4-numeric-input.bp4-small .bp4-button-group.bp4-vertical > .bp4-button {
  flex-basis: 12px;
  width: 24px;
}
.bp4-menu-item.bp4-small {
  font-size: 12px;
  padding: 2px 5px;
}
.bp4-menu.bp4-small {
  padding: 2px;
}
.bp4-menu.bp4-small .bp4-menu-item {
  font-size: 12px;
  padding: 2px 5px;
}
.bp4-menu.bp4-small .bp4-menu-divider {
  margin: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/blueprintSmallStyles.less"],"names":[],"mappings":"AAAA;;EAEE;AACG;EAGW,eAAA;EACA,iBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AADhB;AANK;EAWW,kBAAA;AAFhB;AAKa;EACG,WAAA;AAHhB;AAZK;EAoBO,eAAA;AALZ;AAfK;EAwBO,gBAAA;EACA,WAAA;AANZ;AAYK;EACG,eAAA;EACA,gBAAA;AAVR;AAeK;EACG,YAAA;AAbR;AAYK;EAIO,eAAA;EACA,gBAAA;AAbZ;AAQK;EASO,WAAA;AAdZ","sourcesContent":["@import \"~@blueprintjs/core/lib/less/variables\";\n\n.@{ns}-numeric-input {\n    &.@{ns}-small {\n        .@{ns}-input-group {\n            .@{ns}-input {\n                font-size: 12px;\n                line-height: 24px;\n                height: 24px;\n                padding-left: 8px;\n                padding-right: 8px;\n            }\n\n            .@{ns}-input:not(:first-child) {\n                padding-left: 24px;\n            }\n\n            & > .@{ns}-icon {\n                margin: 4px;\n            }\n        }\n\n        .@{ns}-button {\n            min-width: 24px;\n        }\n\n        .@{ns}-button-group.@{ns}-vertical > .@{ns}-button {\n            flex-basis: 12px;\n            width: 24px;\n        }\n    }\n}\n\n.@{ns}-menu-item {\n    &.@{ns}-small {\n        font-size: 12px;\n        padding: 2px 5px;\n    }\n}\n\n.@{ns}-menu {\n    &.@{ns}-small {\n        padding: 2px;\n\n        .@{ns}-menu-item {\n            font-size: 12px;\n            padding: 2px 5px;\n        }\n\n        .@{ns}-menu-divider {\n            margin: 2px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
