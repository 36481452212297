import * as React from "react";
import * as ReactDOM from "react-dom";

import { detect } from "detect-browser";
import { isNil } from "lodash";
import { SplashScreen } from "./splash";
import { UnsupportedBrowser } from "./unsupportedBrowser";

// Load all required CSS now instead of in app.tsx to avoid visual glitches
import "~/assets/image/favicon.ico";
import "@mil/blueprint-dialogs/lib/src/assets/less/passwordStrengthInput.less";
import "@mil/blueprint-dialogs/lib/src/assets/less/loginDialog.less";
import "~/assets/css/app.less";
import "react-cover-page/themes/default.css";
import "react-flexible-workbench/themes/dark.css";
import "typeface-roboto";
import "typeface-inconsolata";
import "@mil/formatters/lib/src/assets/css/formatters.css";
import "@mil/blueprint-form-components/lib/src/assets/less/blueprintFormComponents.less";
import "~/assets/css/blueprintSmallStyles.less";

// Get the root container where the app will be rendered
const root = document.getElementById("react-root-container");

function isBrowserSupported(): boolean {
    const browser = detect();
    if (!isNil(browser) && !isNil(browser.name)) {
        const name = browser.name;
        if (name === "chrome") {
            return true;
        } else if (name === "firefox") {
            return true;
        } else if (name === "edge") {
            return true;
        } else if (name === "edge-chromium") {
            // Microsoft Edge on Mac
            return true;
        }
    }
    return false;
}

if (isBrowserSupported()) {
    // Create a promise that will eventually load the main application component
    const loadingPromise = import(/* webpackChunkName: "app" */ "./app");

    // Render the splash screen first; when it is done, start rendering the
    // main app, which is imported dynamically so Webpack can optimize it into
    // its own chunk
    ReactDOM.render(<SplashScreen promise={loadingPromise} />, root, () => {
        loadingPromise.then(({ App }) => {
            ReactDOM.render(<App />, root);
        });
    });
} else {
    ReactDOM.render(<UnsupportedBrowser />, root);
}
